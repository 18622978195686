import React, { useState, useRef, useMemo, useEffect } from "react";
import { Button, Modal, Spinner, Form, InputGroup } from 'react-bootstrap';
import Select from "react-select";
import { AgGridReact } from "ag-grid-react";
import { FlowNotification, SupportRequest } from "../../../onsight-plus-ext"
import { authService } from "../../../index";
import { HubConnectionBuilder } from '@microsoft/signalr';
import { Extensions as ExtensionsApi } from "../../../onsight-plus"
import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TableBlock = (props) => {
  const types = [
    {value: "TBD", label: "TBD"},
    {value: "Riser Sim", label: "Riser Sim"},
    {value: "Service", label: "Service"},
    {value: "Sample", label: "Sample"},
  ];

  const gridRef = useRef();
  const [initialLoad, setInitialLoad] = useState(true);
  const initialLoadRef = useRef(true)
  initialLoadRef.current = initialLoad
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [extensionName, setExtensionName] = useState(null)
  const extensionNameRef = useRef(extensionName);
  extensionNameRef.current = extensionName;

  const [editedItemData, setEditedItemData] = useState();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedRequestType, setSelectedRequestType] = useState(types[0]);
  const [selectedRequestPriority, setSelectedRequestPriority] = useState(3);
  const [selectedRequestAssignedTo, setSelectedRequestAssignedTo] = useState({});
  const [selectedRequestNextSteps, setSelectedRequestNextSteps] = useState("");
  const [nextSteps, setNextSteps] = useState([]);
  const [availableStaff, setAvailableStaff] = useState([]);
  const [updatingItem, setUpdatingItem] = useState(false);

  let styleDefinition = {
    headerAlign: "",
    traditionalStylesTitle: "",
    traditionalStylesHeader: "",
    traditionalStylesBody: "",
  };

  const handleSendEmail = async (event, data) => {
    event.target.parentElement.childNodes.forEach(node => {
      node.disabled = true;
    })
    await FlowNotification.emailNotification(extensionNameRef.current, data.EMAIL_RECIPIENT, data.SUBJECT, data.requestId, data.attachment)
    .then(() => {
      FlowNotification.updateNotification(extensionNameRef.current, data.requestId, data.SUBJECT, data.EMAIL_RECIPIENT)
      .then()
      .catch(error => {
        console.log(error)
      })
    })
    .catch(error => {
      console.log(error)
      event.target.parentElement.childNodes.forEach(node => {
        node.disabled = false;
      })
    })
  };

  const cloneRow = async (event, data) => {
    event.target.parentElement.childNodes.forEach(node => {
      node.disabled = true;
    })
    await FlowNotification.addNotification(extensionNameRef.current, data.EMAIL_RECIPIENT, data.SUBJECT, data.attachment)
      .then()
      .catch(error => {
        console.log(error)
        event.target.parentElement.childNodes.forEach(node => {
          node.disabled = false;
        })
      })
  };

  const deleteRow = async (event, data) => {
    event.target.parentElement.childNodes.forEach(node => {
      node.disabled = true;
    })
    await FlowNotification.deleteNotification(extensionNameRef.current, data.requestId)
      .then()
      .catch(error => {
        console.log(error)
        event.target.parentElement.childNodes.forEach(node => {
          node.disabled = false;
        })
      })
  };

  const getEmailButtonClass = (status) => {
    let className = "btn-primary";

    switch (status) {
      case "Unsent":
        className = "btn-success";
        break;
      case "Rejected":
        className = "btn-danger";
        break;
    }

    return "btn-sm " + className;
  };

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var cellDate = new Date(dateAsString);
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };

  const DateRenderer = (props) => {
    const minDate = new Date('Dec 31, 1, 11:59 PM');
    const curDate = new Date(props.data.DATE);
    if (curDate < minDate) {
      return '-';
    }
    return props.data.DATE;
  };

  const CopySendDelButtonRenderer = (props) => {
    if (props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected") {
      return (
        <>
          <Button
            className={getEmailButtonClass(props.data["STATUS"]) + " me-1"}
            onClick={(e) => handleSendEmail(e, props.data)}>
            SEND
          </Button>
          <Button
            className={getEmailButtonClass(props.data["STATUS"]) + " btn-danger"}
            onClick={(e) => deleteRow(e, props.data)}>
            DEL
          </Button>
        </>
      );
    }

    return (
      <Button
        className={getEmailButtonClass(props.data["STATUS"])}
        onClick={(e) => cloneRow(e, props.data)}>
        COPY
      </Button>
    );
  };

  const CallButtonRenderer = (props) => {
    return (
      <Button
        className={getEmailButtonClass("")}
        onClick={(e) => callCustomer(e, props.data)}>
        CALL
      </Button>
    );
  };

  const EditButtonRenderer = (props) => {
    return (
      <Button
      className={getEmailButtonClass("")}
      onClick={(e) => editRow(e, props.data)}>
        EDIT
      </Button>
    );
  };

  const LinksRenderer = (props) => {
    const links = props.data["RECORDS"].map(url => <li><a href={url} target="_blank">Link</a></li>);

    return (
      <ul>
        {links}
      </ul>
    );
  };
  
  const ImageRenderer = (props) => {
    if (props.data.attachment !== undefined && props.data.attachment !== '') {
      return (<button onClick={() => openPdf(props.data.attachment)} type="button" className="btn-sm btn btn-secondary">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
          <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
          <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"></path>
        </svg>
      </button>
      )
    } else {
      return (
        <button type="button" className="disabled btn-sm btn btn-secondary">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-pdf" viewBox="0 0 16 16">
            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z"></path>
          </svg>
        </button>
      )
    }
  };

  function LinkComponent(props) {
    let content = "";
    let url;
    try {
      url = new URL(props.value);
    } catch (ex) {
      console.warn("Invalid URL for table", props.value, ex);
      url = props.value;
    }
    content = (
      <a href={url} rel="noopener noreferrer" target="_blank">
        {props.data.hlTitle === undefined ? props.value : props.data.hlTitle}
      </a>
    );
    return content;
  }

  const openPdf = (attachment) => {
    let pdfWindow = window.open("")
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      encodeURI(attachment) + "'></iframe>"
    )
  }

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 100
    }),
    []
  );

  const createTableStyles = (styleDefinition, bs) => {
    if (bs["fontColorInputTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "color:" + bs["fontColorInputTitle"] + ";";
    } else if (bs["fontSizeInputTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "font-size:" + bs["fontSizeInputTitle"] + ";";
    } else if (bs["fontFamilyInputTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "font-family:" + bs["fontFamilyInputTitle"] + ";";
    } else if (bs["alignmentTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "text-align:" + bs["alignmentTitle"] + ";";
    } else if (bs["italicTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "font-style:" + (bs["italicTitle"] === false ? "normal" : "italic") + ";";
    } else if (bs["boldTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "font-weight:" + (bs["boldTitle"] === false ? "normal" : "bold !important") + ";";
    } else if (bs["bgColorTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "background-color:" + bs["bgColorTitle"] + ";";
    } else if (bs["underlineTitle"] !== undefined) {
      styleDefinition.traditionalStylesTitle = styleDefinition.traditionalStylesTitle + "text-decoration:" + (bs["underlineTitle"] === false ? "none" : "underline") + ";";
    } else if (bs["fontColorInputHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "color:" + bs["fontColorInputHeader"] + ";";
    } else if (bs["fontSizeInputHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "font-size:" + bs["fontSizeInputHeader"] + ";";
    } else if (bs["fontFamilyInputHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "font-family:" + bs["fontFamilyInputHeader"] + ";";
    } else if (bs["alignmentHeader"] !== undefined) {
      if (bs["alignmentHeader"] === "right") {
        styleDefinition.headerAlign = "ag-right-aligned-header";
      }
    } else if (bs["italicHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "font-style:" + (bs["italicHeader"] === false ? "normal" : "italic") + ";";
    } else if (bs["boldHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "font-weight:" + (bs["boldHeader"] === false ? "normal" : "bold !important") + ";";
    } else if (bs["underlineHeader"] !== undefined) {
      styleDefinition.traditionalStylesHeader = styleDefinition.traditionalStylesHeader + "text-decoration:" + (bs["underlineHeader"] === false ? "none" : "underline") + ";";
    } else if (bs["fontColorInputBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "color:" + bs["fontColorInputBody"] + ";";
    } else if (bs["fontSizeInputBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "font-size:" + bs["fontSizeInputBody"] + ";";
    } else if (bs["fontFamilyInputBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "font-family:" + bs["fontFamilyInputBody"] + ";";
    } else if (bs["alignmentBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "text-align:" + bs["alignmentBody"] + ";";
    } else if (bs["italicBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "font-style:" + (bs["italicBody"] === false ? "normal" : "italic") + ";";
    } else if (bs["boldBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "font-weight:" + (bs["boldBody"] === false ? "normal" : "bold !important") + ";";
    } else if (bs["underlineBody"] !== undefined) {
      styleDefinition.traditionalStylesBody = styleDefinition.traditionalStylesBody + "text-decoration:" + (bs["underlineBody"] === false ? "none" : "underline") + ";";
    }
  }

  const createPhantomStyles = (styleDefinition) => {
    const styleTitle = document.createElement("style");
    let styleHtml = String((".cssTitle_" + props.id + " {" + JSON.stringify(styleDefinition.traditionalStylesTitle) + "}").replaceAll('"', ""));
    styleTitle.innerHTML = styleHtml;
    document.head.appendChild(styleTitle);

    const styleHeader = document.createElement("style");
    styleHtml = String((".cssHeader_" + props.id + " {" + JSON.stringify(styleDefinition.traditionalStylesHeader) + "}").replaceAll('"', ""));
    styleHeader.innerHTML = styleHtml;
    document.head.appendChild(styleHeader);

    const styleBody = document.createElement("style");
    styleHtml = String((".cssBody_" + props.id + " {" + JSON.stringify(styleDefinition.traditionalStylesBody) + "}").replaceAll('"', ""));
    styleBody.innerHTML = styleHtml;
    document.head.appendChild(styleBody);

    initialLoadRef.current = false
    setInitialLoad(false)
  }

  const loadExtensionData = async (props) => {
    const ext = await ExtensionsApi.get(props.extensionSelectedId);
    const data = await loadData(ext);
    setRowData(data);

    extensionNameRef.current = ext.name;
    setExtensionName(ext.name);
    await establishConnections();
  };



  // const loadNotifications = async (props) => {
  //   try {
  //     const exts = await ExtensionsApi.get(props.extensionSelectedId);
  //     await FlowNotification.getNotifications(exts.name)
  //     .then(result => {
  //       loadRowData(result.data)
  //       .then()
  //       .catch(error => {
  //         console.log(error)
  //       })
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })

  //     extensionNameRef.current = exts.name;
  //     setExtensionName(exts.name);
  //     await establishConnections()
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const loadRowData = async (requestItems) => {
  //   console.log("loadRowData called");
  //   setRowData(requestItems.map(req => ({
  //     "requestId": req.id,
  //     "EMAIL_RECIPIENT": req.notificationEmailRecipient,
  //     "EMAIL_SENDER": req.notificationEmailSender,
  //     "EMAIL_SENDER_USER": req.notificationUserSender,
  //     "SUBJECT": req.subject,
  //     "STATUS": req.status,
  //     "attachment": req.attachment,
  //     "DATE": format(new Date(req.notificationDate), "PPp", { locale: enUS })
  //   })));
  // };






//  loadData={p.props.children.props.dataSource === "Extension" ? loadData : undefined}
//  columnDefinitions={p.props.children.props.dataSource === "Extension" ? loadColumns : undefined}

  const loadExtensionColumns = async (props) => {
    const ext = await ExtensionsApi.get(props.extensionSelectedId);
    const columns = loadColumns(ext);
    setColumnDefs(columns);
  };

  // if (props.columnDefinitions && initialLoadRef.current) {

    // var requestItems = [
    //   { EMAIL_RECIPIENT: "Email Recipient" },
    //   { EMAIL_SENDER: "Email Sender" },
    //   { EMAIL_SENDER_USER: "Sender Name" },
    //   { SUBJECT: "Email Subject" },
    //   { STATUS: "Email Status" },
    //   { DATE: "Date Sent" },
    //   { attachment: "" },
    // ]

    // let tblColumns = [];

    // requestItems.forEach(col => {
    //   if (props.blockStyles !== undefined) {
    //     if (props.blockStyles.length !== 0) {
    //       props.blockStyles.forEach((bs) => {
    //         createTableStyles(styleDefinition, bs)
    //       });
    //     }
    //   }

    //   if (initialLoadRef.current) {
    //     createPhantomStyles(styleDefinition)
    //   }

    //   for (const [key, value] of Object.entries(col)) {
    //     var tableColumn = {
    //       cellClass: "cssBody_" + props.id,
    //       headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
    //       field: key,
    //       headerName: value,
    //       flex: 1,
    //       minWidth: 100,
    //       wrapText: false,
    //       autoHeight: true
    //     }
    //     if (key === "EMAIL_RECIPIENT") {
    //       tableColumn.editable = (props) => props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected",
    //       tableColumn.filter = 'agSetColumnFilter'
    //     }
    //     if (key === "SUBJECT") {
    //       tableColumn.editable = (props) => props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected",
    //       tableColumn.filter = 'agSetColumnFilter'
    //     }
    //     if (key === "DATE") {
    //       tableColumn.cellRenderer = DateRenderer,
    //       tableColumn.filter = 'agDateColumnFilter',
    //       tableColumn.filterParams = filterParams
    //     }
    //     if (key === "attachment") {
    //       tableColumn.cellRenderer = ImageRenderer
    //     }
    //     tblColumns.push(tableColumn);
    //   }
    // });

    // // column rendered by function
    // var tableColumn = {
    //   cellClass: "cssBody_" + props.id,
    //   headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
    //   field: "",
    //   headerName: "",
    //   flex: 1,
    //   minWidth: 100,
    //   wrapText: true,
    //   autoHeight: true,
    //   cellRenderer: CallButtonRenderer,
    // }
    // tblColumns.push(tableColumn);

  //   setColumnDefs(props.columnDefinitions);
  // }

  const loadTable = () => {
    if (props.mapping.length === 0) {
      console.warn("Missing Mappings For TableBlock", props.id);
    }

    let tblColumns = [];
    if (props.mapping !== undefined) {
      props.mapping.forEach((col) => {

        if (props.blockStyles !== undefined) {
          if (props.blockStyles.length !== 0) {
            props.blockStyles.forEach((bs) => {
              createTableStyles(styleDefinition, bs)
            });
          }
        }

        if (initialLoadRef.current) {
          createPhantomStyles(styleDefinition)
        }

        if (col[1].isUrl) {
          tblColumns.push({
            cellClass: "cssBody_" + props.id,
            headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
            field: col[1].columnName,
            flex: 1,
            minWidth: 200,
            wrapText: true,
            autoHeight: true,
            cellRenderer: LinkComponent,
          });
        } else {
          tblColumns.push({
            cellClass: "cssBody_" + props.id,
            headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
            field: col[1].columnName,
            flex: 1,
            minWidth: 200,
            wrapText: true,
            autoHeight: true,
          });
        }
      });
    }

    let tblData = [];
    if (props.mapping !== undefined) {
      props.mapping.forEach((col) => {
        tblData.push({ [col[1].columnName]: col[1].fieldmappath });
        if (col[1].isUrl) {
          tblData.push({ ["hlTitle"]: col[1].hlTitle });
        }
      });
    }

    let blockData = {};
    const blockArr = [];

    if (props.content !== "" && props.content !== undefined) {
      const arrObj = Object.entries(props.content);
      const blockContent = arrObj.find((c) => c[0] === String(props.dataSource));
      if (blockContent !== undefined) {
        blockContent[1].forEach((bc) => {
          tblData.forEach((td) => {
            const curValue = Object.values(td)[0];
            const curKey = Object.keys(td)[0];
            blockData[curKey] = bc[curValue];
          });
          blockArr.push(blockData);
          blockData = {};
        });
      } else {
        console.warn("Invalid mapping for", props.blockName);
      }
    }

    setColumnDefs(tblColumns);

    if (JSON.stringify(columnDefs) !== JSON.stringify(tblColumns)) {
      setColumnDefs(tblColumns);
    }

    if (JSON.stringify(rowData) !== JSON.stringify(blockArr)) {
      setRowData(blockArr);
    }
  }

  const establishConnections = async () => {
    if (extensionNameRef.current !== null) {
      const websocketUrl = `${FlowNotification.responderHubUrl}?extName=${extensionNameRef.current}`;
      const connection = new HubConnectionBuilder()
        .withUrl(websocketUrl, { accessTokenFactory: async () => await authService.getToken() })
        .withAutomaticReconnect()
        .build();

      connection.start()
        .then(() => {
          connection.on("UpdateNotifications", () => {
            loadNotifications(props)
              .then()
              .catch(error => {
                console.log(error)
              });
          });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }

  const callCustomer = async (event, data) => {
    // TODO
  };

  const editRow = async (event, data) => {
    setEditedItemData(data);
    setSelectedRequestType({value: data.TYPE, label: data.TYPE});
    setSelectedRequestPriority(data.PRIORITY);
    setSelectedRequestAssignedTo({value: data.ASSIGNED_TO_EMAIL, label: data.ASSIGNED_TO});

    const curStep = {value: data.NEXT_STEPS, label: data.NEXT_STEPS};
    const steps = await getFollowingSteps(data.TYPE, curStep);
    setSelectedRequestNextSteps(curStep);
    setNextSteps([curStep, ...steps]);

    const staff = await getAvailableStaff(data.TYPE, curStep);
    setAvailableStaff(staff);

    setShowEditDialog(true);
  };

  const loadNotificationsData = async (extName) => {
    try {
      const notes = await FlowNotification.getNotifications(extName)

      return notes.map(req => ({
        "requestId": req.id,
        "EMAIL_RECIPIENT": req.notificationEmailRecipient,
        "EMAIL_SENDER": req.notificationEmailSender,
        "EMAIL_SENDER_USER": req.notificationUserSender,
        "SUBJECT": req.subject,
        "STATUS": req.status,
        "attachment": req.attachment,
        "DATE": format(new Date(req.notificationDate), "PPp", { locale: enUS })
      }));
    } catch(error) {
      console.log(error)
    }
  };

  const mapSupportRequestData = requests => {
    return requests.map(req => ({
      "requestId": req.id,
      "CUSTOMER_COMPANY": req.customer.company,
      "CUSTOMER_REQUESTOR": req.customer.requester.name,
      "REQUEST_COMMENTS": req.customer.requester.comments,
      "DATE": format(new Date(req.requestDate), "PPp", { locale: enUS }),
      "TYPE": req.calculatedType,
      "RECORDS": req.transcriptUrls,
      "ASSIGNED_TO": req.assignments.Owner?.name ?? "",
      "ASSIGNED_TO_EMAIL": req.assignments.Owner?.email ?? "",
      "NEXT_STEPS": req.nextSteps,
      "PRIORITY": req.priority
    }));
  };

  const loadSupportRequestsData = async (extName) => {
    try {
      const requests = await SupportRequest.getAll(extName);
      return mapSupportRequestData(requests);
    } catch(error) {
      console.log(error)
    }
  };

  const loadNotificationsColumns = () => {
    var requestItems = [
      { EMAIL_RECIPIENT: "Email Recipient" },
      { EMAIL_SENDER: "Email Sender" },
      { EMAIL_SENDER_USER: "Sender Name" },
      { SUBJECT: "Email Subject" },
      { STATUS: "Email Status" },
      { DATE: "Date Sent" },
      { attachment: "" },
    ]

    let tblColumns = [];

    requestItems.forEach(col => {
      if (props.blockStyles !== undefined) {
        if (props.blockStyles.length !== 0) {
          props.blockStyles.forEach((bs) => {
            createTableStyles(styleDefinition, bs)
          });
        }
      }

      // ???
      // if (initialLoadRef.current) {
      //   createPhantomStyles(styleDefinition)
      // }

      for (const [key, value] of Object.entries(col)) {
        var tableColumn = {
          cellClass: "cssBody_" + props.id,
          headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
          field: key,
          headerName: value,
          flex: 1,
          minWidth: 100,
          wrapText: false,
          autoHeight: true
        }
        if (key === "EMAIL_RECIPIENT") {
          tableColumn.editable = (props) => props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected",
          tableColumn.filter = 'agSetColumnFilter'
        }
        if (key === "SUBJECT") {
          tableColumn.editable = (props) => props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected",
          tableColumn.filter = 'agSetColumnFilter'
        }
        if (key === "DATE") {
          tableColumn.cellRenderer = DateRenderer,
          tableColumn.filter = 'agDateColumnFilter',
          tableColumn.filterParams = filterParams
        }
        if (key === "attachment") {
          tableColumn.cellRenderer = ImageRenderer
        }
        tblColumns.push(tableColumn);
      }
    });

    // column rendered by function
    var tableColumn = {
      cellClass: "cssBody_" + props.id,
      headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
      field: "",
      headerName: "",
      flex: 1,
      minWidth: 100,
      wrapText: true,
      autoHeight: true,
      cellRenderer: CopySendDelButtonRenderer,
    }
    tblColumns.push(tableColumn);

    return tblColumns;
  };

  const loadSupportRequestsColumns = () => {
    var requestItems = [
      { CUSTOMER_COMPANY: "Customer" },
      { CUSTOMER_REQUESTOR: "Requestor" },
      { REQUEST_COMMENTS: "Request Comments" },
      { DATE: "Request Date" },
      { TYPE: "Type" },
      { RECORDS: "Records" },
      { ASSIGNED_TO: "Assigned To" },
      { NEXT_STEPS: "Next Steps" },
      { PRIORITY: "Priority" }
    ]

    let tblColumns = [];

    requestItems.forEach(col => {
      if (props.blockStyles !== undefined) {
        if (props.blockStyles.length !== 0) {
          props.blockStyles.forEach((bs) => {
            createTableStyles(styleDefinition, bs)
          });
        }
      }

      // ???
      // if (initialLoadRef.current) {
      //   createPhantomStyles(styleDefinition)
      // }

      for (const [key, value] of Object.entries(col)) {
        var tableColumn = {
          cellClass: "cssBody_" + props.id,
          headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
          field: key,
          headerName: value,
          flex: 1,
          minWidth: 100,
          wrapText: false,
          autoHeight: true
        }
        // if (key === "EMAIL_RECIPIENT") {
        //   tableColumn.editable = (props) => props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected",
        //   tableColumn.filter = 'agSetColumnFilter'
        // }
        // if (key === "SUBJECT") {
        //   tableColumn.editable = (props) => props.data["STATUS"] === "Unsent" || props.data["STATUS"] === "Rejected",
        //   tableColumn.filter = 'agSetColumnFilter'
        // }
        if (key === "DATE") {
          tableColumn.cellRenderer = DateRenderer,
          tableColumn.filter = 'agDateColumnFilter',
          tableColumn.filterParams = filterParams
        }
        if (key === "RECORDS") {
          tableColumn.cellRenderer = LinksRenderer;
        }
        // if (key === "attachment") {
        //   tableColumn.cellRenderer = ImageRenderer
        // }
        tblColumns.push(tableColumn);
      }
    });

    // // column rendered by function
    // tblColumns.push({
    //   cellClass: "cssBody_" + props.id,
    //   headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
    //   field: "",
    //   headerName: "",
    //   flex: 1,
    //   minWidth: 80,
    //   wrapText: true,
    //   autoHeight: true,
    //   cellRenderer: CallButtonRenderer,
    // });
    tblColumns.push({
      cellClass: "cssBody_" + props.id,
      headerClass: ["cssHeader_" + props.id, styleDefinition.headerAlign],
      field: "",
      headerName: "",
      flex: 1,
      minWidth: 60,
      wrapText: true,
      autoHeight: true,
      cellRenderer: EditButtonRenderer,
    });

    return tblColumns;
  };

  const loadData = async (extension) => {
    let rows = [];

    switch (extension?.type) {
      case "FlowNotification":
        rows = await loadNotificationsData(extension.name);
        break;
      case "SupportRequest":
        rows = await loadSupportRequestsData(extension.name);
        break;
    }

    return rows;
  };

  const loadColumns = (extension) => {
    let columns = [];

    switch (extension?.type) {
      case "FlowNotification":
        columns = loadNotificationsColumns();
        break;
      case "SupportRequest":
        columns = loadSupportRequestsColumns();
        break;
    }

    return columns;
  };
  
    const getAvailableStaff = async (requestType, curStep) => {
        const staff = await SupportRequest.getAvailableStaff(extensionNameRef.current, requestType, curStep.value);
        return staff.map(s => ({value: s.emailAddress, label: s.displayName}));
    };

    const getFollowingSteps = async (requestType, curStep) => {
    if (!requestType || requestType === "TBD") {
        return [];
    }

    const configs = await SupportRequest.getStepConfigurations(extensionNameRef.current);
    const config = configs[requestType];
    if (!config) {
        return [];
    }

    if (curStep?.value === "Initial Consultation") {
        const otherStep = config.steps[config.initialStep];
        return [{value: config.initialStep, label: config.initialStep, description: otherStep?.description}];
    }

    const steps = config.steps[curStep.value]?.nextSteps;
    if (!steps) {
        return [];
    }

    // Return array of objects compatible with React Select element's options
    return steps.map(s => ({value: s, label: s, description: config.steps[s]?.description}));


    // switch (curStep?.value) {
//       case "Initial Consult": return [{value: "Doc Review", label: "Doc Review"}]; break;
//       case "Doc Review": return [{value: "Design Consult", label: "Design Consult"}]; break;
//       case "Design Consult": return [{value: "Sim Review", label: "Sim Review"}]; break;
//       case "Sim Review": return [{value: "Sim Revision", label: "Sim Revision"}, {value: "Done", label: "Done"}]; break;
//       case "Sim Revision": return [{value: "Sim Review", label: "Sim Review"}]; break;
//       default: return [] /*[{value: "Initial Consult", label: "Intial Consult"}]*/; break;
//     }
  };

  useEffect(() => {
    if (props.dataSource === "Extension") {
      loadExtensionData(props);
    }
    if (props.dataSource === "Extension" && initialLoadRef.current) {
      loadExtensionColumns(props);
    }
  }, []);

  useEffect(() => {
    if (props.dataSource !== "Extension") {
      loadTable()
    }
  }, [props.content]);

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  });

  const updateLayout = () => {
    props.onLayoutChangeBlock(props.allLayouts);
  };

  const handleRequestTypeChange = newValue => {
    setSelectedRequestType(newValue);
  };
  
  const handleRequestAssignedToChange = newValue => {
    setSelectedRequestAssignedTo(newValue);
  };

  const handleRequestPriorityChange = evt => {
    setSelectedRequestPriority(Number(evt.target.value));
  };

  const handleRequestNextStepsChange = newValue => {
    setSelectedRequestNextSteps(newValue);
  };

  const handleRequestUpdate = async () => {
    setUpdatingItem(true);
    const updatedRequest = await SupportRequest.update(extensionNameRef.current, editedItemData.requestId, {
      assignments: {
        "Owner": {
          name: selectedRequestAssignedTo.label,
          email: selectedRequestAssignedTo.value
        }
      },
      priority: selectedRequestPriority,
      nextSteps: selectedRequestNextSteps.value,
      calculatedType: selectedRequestType.value
    });

    const updatedRows = [...rowData];
    const index = updatedRows.findIndex(item => item.requestId === updatedRequest.id);
    updatedRows[index] = mapSupportRequestData([updatedRequest])[0];
    setRowData(updatedRows);

    setShowEditDialog(false);
    setUpdatingItem(false);
  };

  return (
    <div id={props.keyId} className="card my-2">
      <div className={"card-header cssTitle_" + props.id}>{props.blockName}</div>
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <div style={{ flexGrow: "1" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                className="ag-theme-alpine"
                animateRows="true"
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                rowSelection="multiple"
                suppressRowClickSelection="true"
                domLayout="autoHeight"
                style={{ width: "100%" }}
                pagination={props.rowsToDisplay === '0' ? false : true}
                paginationPageSize={props.rowsToDisplay}
                onGridSizeChanged={updateLayout}
              />
            </div>
          </div>
        </div>
      </div>

      {/* SupportRequests */}
      <Modal show={showEditDialog}>
        <Modal.Header closeButton>Edit Support Request</Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1" className="input-group-text-md">Type</InputGroup.Text>
              <Select
                placeholder="Select Request Type"
                className="select-md"
                onChange={handleRequestTypeChange}
                value={selectedRequestType}
                defaultValue={selectedRequestType}
                options={types} />
          </InputGroup>
          <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon2" className="input-group-text-md">Assigned To</InputGroup.Text>
              <Select
                placeholder="Select Staff Member"
                className="select-md"
                onChange={handleRequestAssignedToChange}
                value={selectedRequestAssignedTo}
                defaultValue={selectedRequestAssignedTo}
                options={availableStaff} />
          </InputGroup>
          <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon3" className="input-group-text-md">Priority</InputGroup.Text>
              <Form.Control type="number" style={{maxWidth: "5em"}}
                name="requestPriority"
                value={selectedRequestPriority} 
                min={1}
                max={5}
                onChange={handleRequestPriorityChange} />
          </InputGroup>
          <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon4" className="input-group-text-md">Next Steps</InputGroup.Text>
              <Select
                placeholder="Select Next Steps"
                className="select-md"
                onChange={handleRequestNextStepsChange}
                value={selectedRequestNextSteps}
                defaultValue={selectedRequestNextSteps}
                options={nextSteps} />
          </InputGroup>
          <span>
            {selectedRequestNextSteps?.description}
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Spinner animation="border" role="status" className={{invisible: !updatingItem}} />
          <Button variant="primary" 
            disabled={updatingItem}
            onClick={handleRequestUpdate}>
              Update
          </Button>
          <Button variant="secondary" onClick={() => setShowEditDialog(false)}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
